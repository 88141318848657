import { getOperatingSystem } from "./Util";

export const sendWhatsAppMessage = (message: string, number: string) => {
  const encodedMessage = encodeURIComponent(message);

  const isDesktop = getOperatingSystem() === 'Desktop';
  const baseUrl = isDesktop ? 'https://api.whatsapp.com/' : 'whatsapp://';
  const whatsappUrl = `${baseUrl}send?phone=${number}&text=${encodedMessage}`;

  const a = document.createElement('a');
  if (isDesktop) {
    a.target = '_blank';
  }
  a.href = whatsappUrl;
  a.click();
};