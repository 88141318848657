import React, { createRef, useState } from 'react';
import { Button, Checkbox } from 'antd';
import { toJS } from 'mobx';
import { confirmAlert } from 'react-confirm-alert';
import { ProductStore } from '../../../../../hooks/ProductStore/productsStore';

import { FormLabel, TextField } from '@material-ui/core';
import BoxWithLabel from '../../../../../components/BoxWithLabel';
import { CouponInput } from './components/CouponInput';

import { ClientOrderInformation } from './ClientOrderInformation';
import { ClosedStore } from '../ClosedStore';
import { CurrentOrder } from './CurrentOrder';
import { PaymentWay } from './PaymentWay';

import { ErrorDialog } from './components/ErrorDialog';
import { NotificationDispatcher, NotificationType } from '../../../../../components/Notification';
import { PaymentCardIframe } from './components/PaymentCardIframe';
import { PaymentLoadingModal } from '../../../../../components/PaymentLoadingModal';
import { ProductsNotAvailableModal } from './components/ProductsNotAvailable';

import { CompanyFeatures } from '../../../../../models/CompanyFeatures';

import {
  Address,
  ClientFare,
  MenuItem,
  Order,
  TaxData,
} from '../../../../../models/DataResponse';
import { Coupon } from '../../../../../models/Coupon';
import { EasypayPaymentResponse } from './models/EasypayPayment';
import { ProductOutOfStockResponse, ProductsCannotBeSold } from '../../../../../models/ProductStock';

import { formattedTotalPrice, getTotalAndSubtotal } from '../../../../../utils/ProductUtil';
import { CARD_PAYMENT_TYPE, CASH_MONEY, MBWAY_PAYMENT_TYPE, PREFIX_LOCALSTORAGE } from '../../../../../utils/Constants';
import { DeliveryType } from '../../../../../utils/DeliveryUtil';
import { getOperatingSystem } from '../../../../../utils/Util';

import ScheduleController from '../../ScheduleController';
import { useCheckStock, useModal } from '../../../../../hooks';
import { useOrderRequest } from '../../hooks/useOrderRequest';
import { useEasypayPayment } from '../../hooks/useEasypayPayment';

import { FormHelperText, Separator } from '../../../../../common-styles';
import { ErrorMessage, LinkTermsAndConditions, ResumeOrderContainer } from './styles';
import { CLIENT_INFO_DATA, LocalStorageService, PAYMENT_EASYPAY } from '../../../../../services/LocalStorageService';
import { useOrderSendMessage } from '../../hooks/useOrderSendMessage';
import { prepareOrderToSend } from '../../../../../utils/PrepareOrderToSendUtil';
import { usePaymentProcess } from '../../../../../hooks/usePaymentProcess';


interface ResumeOrderProps {
  companyName: string;
  companyFeatures: CompanyFeatures;
  handleDeleteAction(product: MenuItem): void;
  handleEditAction(isUpdate: boolean, product: MenuItem): void;
  slug: string;
};

export const ResumeOrder = ({
  companyName,
  companyFeatures,
  handleDeleteAction,
  handleEditAction,
  slug,
}: ResumeOrderProps) => {
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
  const [order, setOrder] = useState({} as Order);
  const [userInfoData, setUserInfoData] = useState<Order | any>(null);
  const [clientFare, setClientFare] = useState<ClientFare>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isClosedStore, setClosedStore] = useState(false);
  const [coupon, setCoupon] = useState<Coupon>();
  const [productsCannotBeSold, setProductsCannotBeSold] = useState<ProductsCannotBeSold>();

  const [paymentCardUrl, setPaymentCardUrl] = useState<string>();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();

  const { toggle: toggleProductNotAvailable, isOpen: isOpenProductNotAvailable } = useModal();

  const { sellsConfiguration, isEasypayEnabled } = companyFeatures;
  const { currency, extraFees } = sellsConfiguration;

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(!isEasypayEnabled ?? true);

  const { checkStock } = useCheckStock(slug);
  const { menuConfiguration, isLoading: isLoadingOrderRequest, executeOrderRequest } = useOrderRequest(slug);
  const { setOrder: setOrderPayment, isLoading: isLoadingOrderSendMessage } = useOrderSendMessage({ companySlug: slug, menuConfiguration });
  const { submitPayment: submitPaymentEasypay } = useEasypayPayment();
  const { isWaitingPayment, setIsWaitingPayment } = usePaymentProcess((isSuccess: boolean, paymentPayload: EasypayPaymentResponse | null) => {
    if (isSuccess) {
      setOrderPayment(paymentPayload?.order);

      const orderCloned = {
        ...order,
        coupon: undefined,
        observation: '',
        scheduledTime: '',
        scheduledDate: '',
      };
  
      LocalStorageService.save(`${CLIENT_INFO_DATA}-${companyName}`, orderCloned);
    } else {
      const isMbway = order.paymentType === MBWAY_PAYMENT_TYPE;
      const message = isMbway ? 'verifique se o seu número está correto, ou se o pagamento foi validado na app do MBway.' :
        'verifique se o seu cartão está correto, ou se o pagamento foi rejeitado na app do seu banco.';
      setPaymentErrorMessage('Ocorreu um erro ao processar o pagamento, ' + message);
      LocalStorageService.clear(PAYMENT_EASYPAY);
    }
  });

  const isLoading = isLoadingOrderRequest || isLoadingOrderSendMessage;
  const formRef1 = createRef<HTMLFormElement>();
  const formRef2 = createRef<HTMLFormElement>();

  const products = toJS(ProductStore.products);

  const onStockValidation = (callbackResult: Function) => {
    checkStock(
      products.map(product => {
        return {
          id: product._id,
          quantity: product.counter,
        }
      }), {
      onSuccess: (result: ProductOutOfStockResponse) => {
        if (!result.notAvailable || !result.withoutStock) {
          setProductsCannotBeSold(result.data);
          toggleProductNotAvailable();
          return;
        }

        callbackResult();
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Erro ao tentar verificar a disponibilidade dos produtos.',
          type: NotificationType.ERROR,
        });
      },
    });
  }

  function showMinOrderValueDialog() {
    const minOrderPrice = sellsConfiguration?.minOrderPrice ?? 0;
    confirmAlert({
      title: 'Atenção!',
      message: `O valor do pedido mínimo sem frete é de ${formattedTotalPrice(
        currency,
        minOrderPrice
      )}.`,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    });
  }

  const handleOrderData = (id: string, value: any) => {
    if (id.includes('.')) {
      const [prefix, key, key2] = id.split('.');

      let { taxData } = order;
      if (!taxData || taxData === undefined) {
        taxData = {} as TaxData;
      }

      if (key2) {
        let { fiscalAddress } = taxData;
        if (!fiscalAddress || fiscalAddress === undefined) {
          fiscalAddress = {} as Address;
        }
        fiscalAddress[key2] = value;
        taxData.fiscalAddress = fiscalAddress;
      } else {
        taxData[key] = value;
      }

      setOrder({
        ...order,
        [prefix]: {
          ...taxData,
        },
      });
    } else {
      setOrder({
        ...order,
        [id]: value,
      });
    }
  }

  const handleClientFareChange = (clientFare?: ClientFare) => setClientFare(clientFare);

  const continueOrderUserData = () => {
    if (!checkStoreOpened()) {
      setClosedStore(true);
      return;
    }

    onStockValidation(() => {
      const userInfoData = localStorage.getItem(
        `${PREFIX_LOCALSTORAGE}userInfoDataOrder-${companyName}`
      );
      if (userInfoData) {
        const userData: Order = JSON.parse(userInfoData);

        confirmAlert({
          title: 'Atenção',
          message: `Identificamos os dados de ${userData.name}, deseja utilizar os mesmos dados?`,
          buttons: [
            {
              label: 'Sim',
              onClick: () => {
                setUserInfoData(userData);
                setOrder(userData);
                setClientFare(userData.clientFare);
                setShowUserInfo(true);
              },
            },
            {
              label: 'Não',
              onClick: () => setShowUserInfo(true),
            },
          ],
        });
      } else {
        setShowUserInfo(true);
      }
    });
  }

  const isValidUsername = () =>
    !order.name || order.name === undefined || order.name.trim().length === 0;

  const handleOrderSubmission = () => {
    if (
      !formRef1?.current?.reportValidity() ||
      !formRef2?.current?.reportValidity()
    ) {
      return;
    }

    if (isValidUsername()) {
      setErrorMessage('O campo nome é obrigatório');
      return;
    }

    if (sellsConfiguration.isTelephoneRequired && !order.phone) {
      setErrorMessage('O campo telefone é obrigatório');
      return;
    }

    if (sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible && sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible
      && sellsConfiguration?.isDatePickerRequired && !order.scheduledDate && sellsConfiguration?.isTimePickerRequired && !order.scheduledTime) {
      setUserInfoData({ ...order, datePickerError: true, timePickerError: true });
      setErrorMessage('Os campos data/hora do pré-agendamento, são obrigatórios');
      return;
    }

    if (sellsConfiguration?.isTimePickerForDeliveryOrPickupVisible && sellsConfiguration?.isTimePickerRequired && !order.scheduledTime) {
      setUserInfoData({ ...order, timePickerError: true });
      setErrorMessage('O campo hora do pré-agendamento é obrigatório');
      return;
    }

    if (sellsConfiguration?.isDatetimeForDeliveryOrPickupVisible && sellsConfiguration?.isDatePickerRequired && !order.scheduledDate) {
      setUserInfoData({ ...order, timePickerError: true });
      setErrorMessage('O campo data do pré-agendamento é obrigatório');
      return;
    }

    if (order.deliveryType === DeliveryType.DELIVERY && companyFeatures.location?.radius && !order.customerAddressInfo?.isAddressAvailable) {
      setErrorMessage('O seu endereço não está disponível para entrega.');
      return;
    }

    setUserInfoData({ ...order, datePickerError: false, timePickerError: false });
    setErrorMessage('');

    order.products = products;

    if (coupon) {
      order.coupon = coupon;
    }

    if (order.deliveryType === DeliveryType.TAKE_AWAY || order.deliveryType === DeliveryType.EAT_IN_LOCO) {
      order.clientFare = null;
      order.address = '';
    } else {
      if (order.canUseSameAddress) {
        if (!order.taxData || order.taxData === undefined) {
          order.taxData = {} as TaxData;
        }
        order.taxData.fiscalAddress = {
          address: order.address,
          number: order.number,
          postalCode: order.postalCode,
        } as Address;
      }

      if (order.deliveryType === undefined) {
        order.deliveryType = sellsConfiguration.hasDelivery ? DeliveryType.DELIVERY : DeliveryType.TAKE_AWAY;
      }
    }

    const { subtotal, total } = getTotalAndSubtotal(order, extraFees);

    const minOrderPrice = sellsConfiguration.minOrderPrice;
    if (
      order.deliveryType === DeliveryType.DELIVERY &&
      subtotal < minOrderPrice
    ) {
      showMinOrderValueDialog();
      return;
    }

    if (order.paymentType !== CASH_MONEY) {
      order.moneyChange = '';
    } else {
      if (order.moneyChange && Number(order.moneyChange) <= total) {
        setErrorMessage('O troco deve ser maior que o valor da compra.');
        return;
      }
    }

    onStockValidation(() => {
      const isMbway = order.paymentType.toUpperCase() === MBWAY_PAYMENT_TYPE;

      if (isEasypayEnabled && menuConfiguration && (isMbway || CARD_PAYMENT_TYPE.includes(order.paymentType.toUpperCase()))) {

        const isDesktop = getOperatingSystem() === 'Desktop';

        setIsWaitingPayment(isDesktop || isMbway);

        const orderBody = prepareOrderToSend({
          companyName,
          menuConfiguration,
          order,
        });

        submitPaymentEasypay({
          amount: total,
          customer: {
            name: order.name,
            email: order.email,
            phone: order.phone.substring(4),
            phone_indicative: order.phone.substring(0, 4),
            fiscal_number: order.taxData?.identification && `PT${order.taxData?.identification}`,
            key: 'final-customer',
            language: 'PT',
          },
          deliveryType: order.deliveryType,
          method: isMbway ? 'mbw' : 'cc',
          slug,
          order: orderBody,
        }, {
          onSuccess: (data: EasypayPaymentResponse) => {
            const paymentUrl = data.method.url;
            if (paymentUrl) {
              if (isDesktop) {
                window.open(paymentUrl, '_blank');
              } else {
                setPaymentCardUrl(paymentUrl);
              }
            }
          },
          onError: () => {
            setIsWaitingPayment(false);
            setPaymentErrorMessage('Ocorreu um erro ao processar o pagamento, por favor tente novamente.');
          },
        });
      } else {
        executeOrderRequest(order);
      }
    });
  }

  function checkStoreOpened() {
    if (!companyFeatures.isEstablishmentOpen) {
      return false;
    }

    const controller = new ScheduleController();
    return controller.isOpenNow(companyFeatures.openingHours);
  }

  function renderOrderButton() {
    return (
      <div style={{ marginTop: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
        <ErrorMessage>{errorMessage}</ErrorMessage>

        {isEasypayEnabled && (
          <Checkbox onChange={(e) => setHasAcceptedTerms(e.target.checked)}>
            Li e aceito os <LinkTermsAndConditions href="https://nowfood.pt/politica-de-privacidade-now-food/" target="_blank">termos e condições </LinkTermsAndConditions>
            & <LinkTermsAndConditions href="https://nowfood.pt/politica-de-vendas/" target="_blank">condições gerais</LinkTermsAndConditions>
          </Checkbox>
        )}

        <Button
          disabled={!hasAcceptedTerms}
          loading={isLoading || isWaitingPayment}
          onClick={handleOrderSubmission}
          style={{ marginTop: '16px', fontWeight: 'bold' }}
          type='primary'
        >
          Finalizar pedido
        </Button>

        <FormHelperText style={{ textAlign: 'center', marginTop: '20px', fontSize: '12px' }}>
          Ao clicar em 'Finalizar pedido', você será redirecionado para o Whatsapp.
        </FormHelperText>
      </div>
    );
  }

  return (
    <>
      {paymentCardUrl && (
        <PaymentCardIframe
          url={paymentCardUrl}
          onClose={() => {
            setPaymentCardUrl(undefined);
            setIsWaitingPayment(true);
          }} />
      )}

      {paymentErrorMessage && (
        <ErrorDialog
          title='Falha no pagamento'
          error={paymentErrorMessage}
          onClose={() => { setPaymentErrorMessage(undefined) }} />
      )}

      <PaymentLoadingModal
        isOpen={isWaitingPayment}
      >
        {
          order.paymentType?.toUpperCase() === MBWAY_PAYMENT_TYPE ? (
            <>
              <h3>Pagamento a ser processado.</h3>
              <h4 style={{ textAlign: 'center' }}>Deverá finalizar o pagamento no MBWay. Após o pagamento, retorne a este ecrã. Por favor, não feche esta página.</h4>
            </>
          ) : (
            <h3>Estamos a processar o seu pagamento, por favor aguarde e não feche a página.</h3>
          )
        }
      </PaymentLoadingModal>

      <ResumeOrderContainer>
        <FormLabel
          component="legend"
          style={{
            fontSize: '22px',
            fontWeight: 'bold',
            paddingTop: '10px',
            paddingBottom: '15px',
            color: '#3f3e3e',
            textAlign: 'center',
          }}
        >
          {showUserInfo ? 'Resumo do Pedido' : 'Meu Pedido'}
        </FormLabel>

        <Separator />

        {!showUserInfo && (
          <CurrentOrder
            hideTotal
            products={products}
            sellsConfiguration={sellsConfiguration}
            handleDeleteAction={handleDeleteAction}
            handleEditAction={handleEditAction}
          />
        )}

        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          {showUserInfo ? (
            <>
              <ClientOrderInformation
                companyFeatures={companyFeatures}
                formRef={formRef1}
                handleClientFareChange={handleClientFareChange}
                handleOrderData={handleOrderData}
                userInfoData={userInfoData}
                slug={slug}
              />

              {sellsConfiguration.showCouponFeature && (
                <CouponInput
                  slug={slug}
                  phone={order.phone}
                  handleCouponCode={(couponResponse: Coupon) =>
                    setCoupon(couponResponse)
                  }
                />
              )}

              <BoxWithLabel title="Produtos">
                <CurrentOrder
                  products={products}
                  clientFare={clientFare}
                  coupon={coupon}
                  sellsConfiguration={sellsConfiguration}
                  handleDeleteAction={handleDeleteAction}
                  handleEditAction={handleEditAction}
                />
              </BoxWithLabel>

              {sellsConfiguration.paymentMethods.length > 0 && (
                <PaymentWay
                  formRef={formRef2}
                  paymentMethods={sellsConfiguration.paymentMethods}
                  handleOrderData={handleOrderData}
                  userInfoData={userInfoData}
                />
              )}

              <TextField
                id="observation"
                variant="outlined"
                type="text"
                className="form-general-input"
                label="Observações gerais"
                multiline
                rows={4}
                inputProps={{ maxLength: 300 }}
                onChange={(e) => handleOrderData('observation', e.target.value)}
                style={{ margin: '15px 8px', width: '100%' }}
              />

              <Separator />

              {renderOrderButton()}
            </>
          ) : (
            <Button
              style={{ marginTop: '16px', fontWeight: 'bold' }}
              onClick={continueOrderUserData}
              type='primary'
            >
              Continuar o pedido
            </Button>
          )}
        </div>

        {isClosedStore && (
          <ClosedStore
            companyFeatures={companyFeatures}
            dismissPage={() => setClosedStore(false)}
          />
        )}
      </ResumeOrderContainer>

      {productsCannotBeSold && (
        <ProductsNotAvailableModal
          isOpen={isOpenProductNotAvailable}
          close={toggleProductNotAvailable}
          productsCannotBeSold={productsCannotBeSold} />)}
    </>
  );
};
