import { isEmpty } from "lodash";
import { useFetch } from "../../../../../../hooks/useFetch";

interface UseCustomerAddressInfoProps {
  address: string;
  storeAddress: string;
  radius?: number;
  slug: string;
}

export interface CustomerAddressInfo {
  distance: number;
  isAddressAvailable: boolean;
  latitude: number;
  longitude: number;
  postalCode: string;
}

const CUSTOMER_ADDRESS_INFO = 'customerAddressInfo';

export const useCustomerAddressInfo = ({ address, storeAddress, radius, slug }: UseCustomerAddressInfoProps) => {
  const { data, ...rest } = useFetch<CustomerAddressInfo>({
    enabled: !isEmpty(address),
    queryKey: [CUSTOMER_ADDRESS_INFO, address],
    url: `api/address/customer-info?customerAddress=${address}&storeAddress=${storeAddress}&radius=${radius}&slug=${slug}`,
  });

  return { data, ...rest };
};